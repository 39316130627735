import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { BlogPostOverview, Taxonomy } from '../model';
import { TaxonomyView } from '../components/TaxonomyView';
import { PageLayout } from '../components/PageLayout';

interface DataProps {
  allCategoriesJson: {
    nodes: ReadonlyArray<Taxonomy>;
  };
  allBlogPostsJson: {
    nodes: ReadonlyArray<BlogPostOverview>;
  };
}

interface PageContext {
  id: string;
  jsonId: number;
  name: string;
}

const Category = ({ data, pageContext }: PageProps<DataProps, PageContext>) => {
  return (
    <PageLayout title={`Genre: ${pageContext.name}`} description="">
      <TaxonomyView
        term={pageContext.name}
        blogPosts={data.allBlogPostsJson.nodes}
      />
    </PageLayout>
  );
};

export const query = graphql`
  query CategoriesQuery($jsonId: Int) {
    allCategoriesJson(filter: { jsonId: { eq: $jsonId } }) {
      nodes {
        ...CategoriesJsonFragment
      }
    }
    allBlogPostsJson(
      sort: { fields: meta___created, order: DESC }
      filter: { meta: { categories: { in: [$jsonId] } } }
    ) {
      nodes {
        ...BlogPostOverviewFragment
      }
    }
  }
`;

export default Category;
